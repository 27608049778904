const CONTACTS = {
  adress: {
    index: '',
    street: 'Place du 8 Mai 1945',
    zip: '38460',
    city: 'Cremieu',
  },
  phone: '0474907880',
  email: 'ogec.cremieu@wanadoo.fr',
}

export default CONTACTS
