export const SET_PRIVATE_ACCOUNT_FETCH_ALERT = 'SET_PRIVATE_ACCOUNT_FETCH_ALERT'
export const SET_PRIVATE_ACCOUNT_MUTATE_ALERT =
  'SET_PRIVATE_ACCOUNT_MUTATE_ALERT'
export const SET_INFORMATIONS_INSCRIPTIONS_ALERT =
  'SET_INFORMATIONS_INSCRIPTIONS_ALERT'
export const SET_ALBUM_MUTATE_ALERT = 'SET_ALBUM_MUTATE_ALERT'
export const SET_ALBUM_FETCH_ALERT = 'SET_ALBUM_FETCH_ALERT'
export const SET_ALBUM_PAGE_MUTATE_ALERT = 'SET_ALBUM_PAGE_MUTATE_ALERT'
export const SET_ALBUM_PAGE_FETCH_ALERT = 'SET_ALBUM_PAGE_FETCH_ALERT'
export const SET_PAPER_FETCH_ALERT = 'SET_PAPER_FETCH_ALERT'
export const SET_PAPER_MUTATE_ALERT = 'SET_PAPER_MUTATE_ALERT'
export const SET_PAGE_FETCH_ALERT = 'SET_PAGE_FETCH_ALERT'
export const SET_PAGE_MUTATE_ALERT = 'SET_PAGE_MUTATE_ALERT'
export const SET_LOGIN_ALERT = 'SET_LOGIN_ALERT'
export const SET_REGISTER_ALERT = 'SET_REGISTER_ALERT'
export const SET_MUTATE_ALERT = 'SET_MUTATE_ALERT'
export const SET_FETCH_ALERT = 'SET_FETCH_ALERT'
export const SET_EDITOR_FETCH_ALERT = 'SET_EDITOR_FETCH_ALERT'
export const SET_PARAMETERS_FETCH_ALERT = 'SET_PARAMETERS_FETCH_ALERT'
export const SET_PARAMETERS_MUTATE_ALERT = 'SET_PARAMETERS_MUTATE_ALERT'
export const SET_MEMBRES_FETCH_ALERT = 'SET_MEMBRES_FETCH_ALERT'
export const SET_MEMBRES_MUTATE_ALERT = 'SET_MEMBRES_MUTATE_ALERT'
