const PARTNERS = [
  {
    name: 'La Paroisse saint Martin',
    link: 'https://paroissecremieu.fr/',
  },
  {
    name: "Direction Diocésaine de l'Enseignement Catholique ",
    link: 'https://ec38.org/',
  },
  {
    name: 'La Féderation des OGEC',
    link: 'https://www.fnogec.org/',
  },
]
export default PARTNERS
