const APELTEAM = [
  {
    role: 'presidente',
    gender: 'madame',
    firstname: 'Chrystèle',
    lastname: 'Poncet',
  },
  {
    role: 'secrétaire',
    gender: 'monsieur',
    firstname: 'Sebastien',
    lastname: 'Gautron',
  },
  {
    role: 'trésorière',
    gender: 'madame',
    firstname: 'Nadège',
    lastname: 'Carle',
  },
]

export default APELTEAM
