const OGECTEAM = [
  {
    role: 'president',
    gender: 'monsieur',
    firstname: 'Emmanuel',
    lastname: 'Gerilli',
  },
  {
    role: 'vice-president',
    gender: 'madame',
    firstname: 'Barbara',
    lastname: 'Manugal',
  },
  {
    role: 'tresorier',
    gender: 'madame',
    firstname: 'Alain',
    lastname: 'Mullot',
  },
]

export default OGECTEAM
