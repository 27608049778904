import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    verticalAlign: 'top',
    maxHeight: '25vh ',
    textAlign: 'left !important',
    overflow: 'hidden',
  },
  svg: {
    height: '24vh',

    marginTop: '-40px !important',
    marginBottom: '-40px !important',
  },
  text: {
    fill: 'white',
  },
  bar: {
    fill: 'white',
  },
  pencils: {
    fill: theme.palette.primary.main,
  },
}))

function FooterLogo() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {/* <img src={logo} width="250" /> */}

      <svg
        className={classes.svg}
        version="1.1"
        baseProfile="tiny"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 283.5 283.5"
        // viewBox="0 0 32 32"
        preserveAspectRatio="none"
      >
        <g className={classes.text}>
          <path
            d="M41.1,162c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2h-8.6v5.7h6.3
		c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4S39,170,38.8,170h-6.3v5.7h8.6c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4
		c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2H32c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4v-13.7c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2
		H41.1z M36.4,160.9c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0-0.3,0.1-0.4l2.3-2.3
		c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2L36.4,160.9z"
          />
          <path
            d="M54.9,174.2c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4c0,0.2,0,0.3-0.1,0.4c-0.7,0.5-1.4,0.9-2.2,1.2
		c-0.8,0.3-1.6,0.4-2.5,0.4c-1,0-2-0.2-2.8-0.6c-0.9-0.4-1.7-0.9-2.4-1.6c-0.7-0.7-1.2-1.5-1.6-2.4c-0.4-0.9-0.6-1.8-0.6-2.8
		c0-2,0.7-3.8,2.2-5.2c1.5-1.5,3.2-2.2,5.3-2.2c1.8,0,3.3,0.6,4.7,1.7c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4
		s-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.5-0.2c-1.1-0.9-2.3-1.3-3.8-1.3c-1.7,0-3.2,0.6-4.4,1.8c-1.2,1.2-1.8,2.7-1.8,4.4
		c0,1.7,0.6,3.2,1.8,4.4s2.7,1.8,4.4,1.8c1.5,0,2.8-0.5,3.9-1.4C54.7,174.3,54.8,174.2,54.9,174.2z"
          />
          <path
            d="M71.9,169.4c0,2-0.7,3.8-2.2,5.3c-1.4,1.4-3.2,2.2-5.2,2.2c-1,0-2-0.2-2.8-0.6c-0.9-0.4-1.7-0.9-2.4-1.6
		c-0.7-0.7-1.2-1.5-1.6-2.4c-0.4-0.9-0.6-1.8-0.6-2.8c0-2,0.7-3.8,2.2-5.2c1.5-1.5,3.2-2.2,5.3-2.2c2.1,0,3.8,0.7,5.2,2.2
		C71.2,165.6,71.9,167.4,71.9,169.4z M64.5,163.2c-1.7,0-3.2,0.6-4.4,1.8c-1.2,1.2-1.8,2.7-1.8,4.4c0,1.7,0.6,3.2,1.8,4.4
		c1.2,1.2,2.7,1.8,4.4,1.8c1.7,0,3.2-0.6,4.4-1.8c1.2-1.2,1.8-2.7,1.8-4.4c0-1.7-0.6-3.2-1.8-4.4C67.7,163.8,66.2,163.2,64.5,163.2z
		"
          />
          <path
            d="M73.5,162.6c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4v13.1h7.8c0.2,0,0.3,0.1,0.4,0.2
		s0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2h-8.4c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4V162.6z"
          />
          <path
            d="M94.5,162c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2h-8.6v5.7h6.3
		c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2h-6.3v5.7h8.6c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4
		c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2h-9.1c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4v-13.7c0-0.2,0.1-0.3,0.2-0.4
		s0.2-0.2,0.4-0.2H94.5z"
          />
          <path
            d="M102.1,174.5c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2c0.2,0,0.4,0.1,0.5,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		c0.3,0.3,0.6,0.6,1,0.8c0.4,0.2,0.9,0.3,1.5,0.3c0.8,0,1.5-0.3,2.1-0.8c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.9,0.3-1.5
		c0-0.8-0.3-1.5-0.8-2.1c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.5,0-0.9-0.2-1.4-0.4c-0.4-0.2-0.8-0.5-1.1-0.9
		c-0.3-0.3-0.5-0.7-0.6-1.1s-0.2-0.8-0.2-1.2c0-1.2,0.4-2.1,1.3-2.8c0.7-0.6,1.5-0.9,2.4-0.9c1,0,2,0.4,2.7,1.3
		c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2c-0.2,0-0.3,0-0.4-0.1
		c-0.1-0.1-0.2-0.3-0.4-0.5c-0.5-0.5-1-0.8-1.7-0.9c-0.7,0-1.3,0.2-1.8,0.6c-0.5,0.4-0.8,1-0.9,1.7c0,0.3,0,0.7,0.1,1
		c0.1,0.3,0.3,0.6,0.5,0.8c0.2,0.3,0.5,0.5,0.8,0.6c0.3,0.1,0.6,0.2,0.9,0.3c0.6,0.1,1.1,0.2,1.6,0.5c0.5,0.3,1,0.6,1.4,1.1
		c0.3,0.4,0.6,0.8,0.8,1.3c0.2,0.5,0.3,1,0.3,1.5c0,0.9-0.2,1.5-0.4,2c-0.3,0.5-0.6,1-1.1,1.3c-0.8,0.7-1.8,1-2.8,1
		c-1.3,0-2.4-0.5-3.4-1.5c-0.1-0.1-0.2-0.3-0.3-0.4C102.1,174.8,102.1,174.6,102.1,174.5z"
          />
          <path
            d="M115.1,171.5l-1.9,5c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4
		c0-0.1,0-0.2,0.1-0.3l5.3-13.6c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.1,0.1,0.2
		l5.3,13.6c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l-1.9-5
		H115.1z M120.3,170.4l-2.4-6.2l-2.4,6.2H120.3z"
          />
          <path
            d="M126.4,162.6c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4v13.7
		c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4V162.6z"
          />
          <path
            d="M131.2,176.3c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4v-13.7
		c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l9.6,12.4v-12c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2
		c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4v13.7c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2l-9.7-12.4V176.3z"
          />
          <path
            d="M144.1,163.2c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2h9.9c0.2,0,0.3,0.1,0.4,0.2
		s0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2h-4.4v13.1c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2
		c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4v-13.1H144.1z"
          />
          <path
            d="M164.6,171.5l-1.9,5c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4
		c0-0.1,0-0.2,0.1-0.3l5.3-13.6c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.4-0.2s0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.1,0.1,0.2l5.3,13.6
		c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l-1.9-5H164.6z
		 M169.9,170.4l-2.4-6.2l-2.4,6.2H169.9z"
          />
          <path
            d="M175.1,162.6c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2s0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4v9.1c0,1.1,0.4,2,1.2,2.8
		c0.8,0.8,1.7,1.2,2.8,1.2c1.1,0,2.1-0.4,2.8-1.2s1.2-1.7,1.2-2.8v-9.1c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2
		c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4v9.1c0,1.4-0.5,2.6-1.5,3.6s-2.2,1.5-3.6,1.5s-2.6-0.5-3.6-1.5c-0.5-0.5-0.9-1.1-1.1-1.7
		c-0.2-0.6-0.4-1.3-0.4-2V162.6z"
          />
          <path
            d="M199.3,174.8c0,0.2,0,0.3-0.1,0.4c-0.7,0.5-1.4,0.9-2.2,1.2c-0.8,0.3-1.6,0.4-2.5,0.4c-1,0-2-0.2-2.8-0.6
		c-0.9-0.4-1.7-0.9-2.4-1.6c-0.7-0.7-1.2-1.5-1.6-2.4s-0.6-1.8-0.6-2.8c0-2,0.7-3.8,2.2-5.2c1.5-1.5,3.2-2.2,5.3-2.2
		c1.8,0,3.3,0.6,4.7,1.7c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.5-0.2
		c-1.1-0.9-2.3-1.3-3.8-1.3c-1.7,0-3.2,0.6-4.4,1.8c-1.2,1.2-1.8,2.7-1.8,4.4c0,1.7,0.6,3.2,1.8,4.4s2.7,1.8,4.4,1.8
		c1.4,0,2.6-0.4,3.7-1.2v-3.8h-3.2c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4s0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2h3.8
		c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4V174.8z"
          />
          <path
            d="M201.1,162.6c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4v9.1c0,1.1,0.4,2,1.2,2.8
		c0.8,0.8,1.7,1.2,2.8,1.2c1.1,0,2-0.4,2.8-1.2s1.2-1.7,1.2-2.8v-9.1c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
		s0.2,0.2,0.2,0.4v9.1c0,1.4-0.5,2.6-1.5,3.6s-2.2,1.5-3.6,1.5c-1.4,0-2.6-0.5-3.6-1.5c-0.5-0.5-0.9-1.1-1.1-1.7
		c-0.2-0.6-0.4-1.3-0.4-2V162.6z"
          />
          <path
            d="M213.1,174.5c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2c0.2,0,0.4,0.1,0.5,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		c0.3,0.3,0.6,0.6,1,0.8c0.4,0.2,0.9,0.3,1.5,0.3c0.8,0,1.5-0.3,2.1-0.8c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.9,0.3-1.5
		c0-0.8-0.3-1.5-0.8-2.1c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-0.5,0-0.9-0.2-1.4-0.4s-0.8-0.5-1.1-0.9
		c-0.3-0.3-0.5-0.7-0.6-1.1s-0.2-0.8-0.2-1.2c0-1.2,0.4-2.1,1.3-2.8c0.7-0.6,1.5-0.9,2.4-0.9c1,0,2,0.4,2.7,1.3
		c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2c-0.2,0-0.3,0-0.4-0.1
		c-0.1-0.1-0.2-0.3-0.4-0.5c-0.5-0.5-1-0.8-1.7-0.9c-0.7,0-1.3,0.2-1.8,0.6c-0.5,0.4-0.8,1-0.9,1.7c0,0.3,0,0.7,0.1,1
		c0.1,0.3,0.3,0.6,0.5,0.8c0.2,0.3,0.5,0.5,0.8,0.6c0.3,0.1,0.6,0.2,0.9,0.3c0.6,0.1,1.1,0.2,1.6,0.5c0.5,0.3,1,0.6,1.4,1.1
		c0.3,0.4,0.6,0.8,0.8,1.3c0.2,0.5,0.3,1,0.3,1.5c0,0.9-0.2,1.5-0.4,2c-0.3,0.5-0.6,1-1.1,1.3c-0.8,0.7-1.8,1-2.8,1
		c-1.3,0-2.4-0.5-3.4-1.5c-0.1-0.1-0.2-0.3-0.3-0.4C213.1,174.8,213.1,174.6,213.1,174.5z"
          />
          <path
            d="M223.6,163.2c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2h9.9c0.2,0,0.3,0.1,0.4,0.2
		s0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2h-4.4v13.1c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2
		c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4v-13.1H223.6z"
          />
          <path
            d="M236.7,162.6c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2s0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4v13.7c0,0.2-0.1,0.3-0.2,0.4
		s-0.2,0.2-0.4,0.2s-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4V162.6z"
          />
          <path
            d="M241.5,176.3c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2s-0.3-0.1-0.4-0.2s-0.2-0.2-0.2-0.4v-13.7
		c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l9.6,12.4v-12c0-0.2,0.1-0.3,0.2-0.4s0.2-0.2,0.4-0.2
		c0.2,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4v13.7c0,0.2-0.1,0.3-0.2,0.4s-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2l-9.7-12.4V176.3z"
          />
        </g>
        <g className={classes.text}>
          <path
            d="M103.7,188.7c0-0.6,0.1-1.1,0.4-1.5c0.3-0.4,0.7-0.8,1.1-1.2c0.5-0.3,1-0.6,1.6-0.8c0.6-0.2,1.2-0.4,1.8-0.5
		c0.6-0.1,1.2-0.2,1.7-0.3c0.6-0.1,1-0.1,1.4-0.1c0.2,0,0.3,0.1,0.4,0.2s0.2,0.3,0.2,0.4c0,0.2,0,0.3-0.1,0.4c0,0.1-0.1,0.3-0.2,0.5
		c-0.3,0-0.7-0.1-1.2-0.1c-0.5,0-1,0-1.5,0.1s-1,0.2-1.5,0.3c-0.5,0.1-1,0.3-1.4,0.6c-0.4,0.2-0.8,0.5-1,0.9
		c-0.3,0.3-0.4,0.8-0.4,1.3c0,0.4,0.1,0.8,0.2,1.2c0.1,0.3,0.4,0.6,0.6,0.8c0.3,0.2,0.5,0.4,0.9,0.6c0.3,0.1,0.7,0.3,1,0.3
		c0.4,0.1,0.7,0.1,1.1,0.2c0.4,0,0.7,0,1.1,0c0.4,0,0.8,0,1.2,0s0.8,0,1.1-0.1s0.7-0.1,1.1-0.2c0.4-0.1,0.7-0.2,1.1-0.4
		c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.6,0.2-1,0.2
		c-0.4,0-0.7,0.1-1.1,0.1s-0.7,0-1,0c-0.3,0-0.5,0-0.7,0c-0.4,0-0.9,0-1.4-0.1c-0.5-0.1-1-0.2-1.4-0.3c-0.5-0.1-0.9-0.3-1.3-0.6
		c-0.4-0.2-0.8-0.5-1.1-0.8s-0.6-0.7-0.8-1.2C103.8,189.8,103.7,189.3,103.7,188.7z"
          />
          <path
            d="M115.9,189.4C115.9,189.4,115.9,189.4,115.9,189.4c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0,0,0v-0.1c0,0,0,0,0,0
		c0.1-0.1,0.3-0.2,0.4-0.3s0.2-0.1,0.4-0.1c0.1,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0
		s0.1-0.1,0.2-0.2s0.3-0.3,0.5-0.5s0.4-0.4,0.7-0.6c0.3-0.2,0.6-0.4,1-0.6c0.4-0.2,0.7-0.3,1.2-0.5c0.4-0.1,0.9-0.2,1.4-0.2h0.1
		c0.1,0,0.2,0,0.3,0.1c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0.1,0.5,0.1c0.2,0,0.3,0.1,0.3,0.1c0,0,0,0,0.1,0.1s0.1,0.1,0.1,0.1
		s0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1l0,0l0,0v0c0,0,0,0,0,0c0,0-0.1,0.1-0.2,0.2h-3.4c-0.3,0-0.6,0.1-0.9,0.2
		s-0.6,0.4-0.9,0.6s-0.5,0.5-0.7,0.9c-0.2,0.3-0.3,0.6-0.3,0.9v2.8c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1
		c-0.1,0-0.1,0-0.2,0h0c0,0,0,0,0,0c-0.1,0-0.1,0-0.1-0.1s-0.1-0.1-0.1-0.1L115.9,189.4z"
          />
          <path
            d="M124.7,189.8c0-0.4,0.1-0.9,0.4-1.3c0.2-0.4,0.6-0.8,0.9-1.1c0.4-0.3,0.8-0.6,1.3-0.7c0.5-0.2,0.9-0.3,1.3-0.3
		c0.3,0,0.7,0,1,0.1s0.6,0.2,0.9,0.4c0.3,0.2,0.5,0.4,0.6,0.7c0.2,0.3,0.2,0.6,0.2,0.9c0,0.2,0,0.3,0,0.4s-0.1,0.2-0.1,0.3
		c-0.1,0.1-0.1,0.1-0.2,0.2s-0.2,0.1-0.4,0.2c-0.1,0-0.2,0-0.4,0.1s-0.3,0.1-0.5,0.1c-0.2,0.1-0.4,0.1-0.6,0.2
		c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.1-0.5,0.1s-0.3,0.1-0.4,0.1c0,0-0.1,0-0.3,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0.1
		c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.5,0.4
		c0.2,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.1,0.8,0.1s0.5,0,0.8,0s0.4,0,0.6,0c0.7,0,1.3,0,1.9-0.1s1.3-0.1,1.9-0.1v0.2
		c0,0.1,0,0.2,0,0.2s0,0.2-0.1,0.2s-0.1,0.1-0.2,0.1c-0.5,0.1-0.9,0.2-1.3,0.2c-0.4,0.1-0.7,0.1-1.1,0.1c-0.3,0-0.7,0-1.1,0
		c-0.4,0-0.8,0-1.2,0h-0.3c-0.1,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0H127c-0.4-0.1-0.8-0.3-1-0.5s-0.5-0.5-0.7-0.8
		c-0.2-0.3-0.3-0.7-0.4-1C124.7,190.5,124.7,190.2,124.7,189.8z M125.7,185.8c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.3
		c0.5-0.2,0.9-0.3,1.2-0.3c0.3-0.1,0.6-0.1,0.9-0.2c0.3-0.1,0.6-0.1,0.9-0.3c0.3-0.1,0.7-0.3,1.2-0.6c0,0,0.1-0.1,0.1-0.1
		c0.1-0.1,0.1-0.1,0.1-0.1h0c0,0,0,0,0,0c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.4
		c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.5,0.2s-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.4,0.1s-0.4,0.1-0.6,0.2
		c-0.2,0.1-0.5,0.2-0.8,0.2s-0.5,0.1-0.8,0.2s-0.5,0.1-0.8,0.1C126.1,185.9,125.9,185.9,125.7,185.8z M129,187.5c-0.2,0-0.4,0-0.6,0
		s-0.4,0.1-0.7,0.2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.2,0.4-0.2,0.6h0.8
		c0.3,0,0.6,0,0.9,0c0.3,0,0.6-0.1,0.9-0.1c0.3-0.1,0.5-0.1,0.8-0.3s0.4-0.3,0.5-0.5s0.2-0.4,0.2-0.7v-0.1l-0.2-0.2c0,0-0.1,0-0.1,0
		c-0.1,0-0.1,0-0.2,0s-0.2,0-0.2,0C129.1,187.5,129.1,187.5,129,187.5z"
          />
          <path
            d="M145.3,188.8c-0.4,0-0.7,0.1-1,0.2c-0.3,0.2-0.5,0.4-0.8,0.6s-0.4,0.5-0.6,0.8s-0.4,0.5-0.6,0.8s-0.4,0.4-0.7,0.6
		c-0.2,0.2-0.5,0.2-0.9,0.2c0-0.2,0-0.5,0-0.8c0-0.3,0-0.6-0.1-1c-0.1-0.3-0.1-0.6-0.2-1c-0.1-0.3-0.2-0.6-0.4-0.8
		c-0.2-0.2-0.4-0.4-0.6-0.6s-0.6-0.2-0.9-0.2c-0.4,0-0.8,0.1-1.1,0.3c-0.3,0.2-0.6,0.5-0.8,0.8s-0.4,0.7-0.5,1.1
		c-0.1,0.4-0.2,0.8-0.2,1.2c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.4,0.1
		c0-0.3,0-0.6-0.1-1c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.3-0.1-0.6-0.1-0.9v-0.1c0-0.1,0-0.1,0-0.2
		c0-0.1,0-0.1,0-0.2v-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.5
		c0.1,0.2,0.1,0.4,0.1,0.5s0,0.3,0,0.4h0.2c0.4-0.6,0.9-1,1.5-1.3c0.6-0.3,1.2-0.4,1.9-0.4c0.5,0,0.9,0.1,1.2,0.3
		c0.3,0.2,0.6,0.5,0.8,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.3,0.8,0.4,1.1h0.2c0.3-0.3,0.6-0.5,0.9-0.8c0.3-0.3,0.6-0.5,0.9-0.7
		c0.3-0.2,0.7-0.3,1-0.5c0.4-0.1,0.8-0.2,1.2-0.2c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.5,0.5,0.7,0.8s0.4,0.7,0.6,1.1s0.3,0.8,0.4,1.2
		c0.1,0.4,0.2,0.8,0.2,1.2c0.1,0.4,0.1,0.7,0.1,1c-0.5,0-0.8-0.1-1.1-0.3c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.3-0.2-0.7-0.2-1.1
		s-0.1-0.7-0.2-1.1c-0.1-0.3-0.3-0.6-0.5-0.8S145.8,188.8,145.3,188.8z"
          />
          <path
            d="M150,193.1c-0.1-0.3-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.2-0.9c0-0.3-0.1-0.6-0.1-1c0-0.3,0-0.6,0-0.9c0,0,0-0.1,0-0.2
		c0-0.1,0-0.2,0-0.3s0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.2c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0.1,0.4,0.3
		c0.1,0.2,0.2,0.4,0.3,0.7s0.1,0.6,0.2,0.9c0,0.3,0.1,0.7,0.1,1c0,0.3,0,0.6,0,0.8s0,0.4,0,0.5c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3
		c0,0.1,0,0.2-0.1,0.2c0,0.1-0.1,0.1-0.2,0.1h-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0
		C150,193.1,150,193.1,150,193.1z M149.8,184.4c0-0.3,0.2-0.6,0.6-0.7c0.1,0,0.2,0,0.2,0c0.1,0.1,0.1,0.1,0.2,0.3
		c0,0.1,0.1,0.2,0.1,0.3s0,0.2,0,0.2c0,0.1,0,0.1-0.1,0.2s-0.1,0.1-0.1,0.1s-0.1,0-0.2,0h-0.2c-0.2,0-0.3,0-0.4-0.1
		S149.8,184.7,149.8,184.4z"
          />
          <path
            d="M151.7,189.8c0-0.4,0.1-0.9,0.4-1.3c0.2-0.4,0.6-0.8,0.9-1.1c0.4-0.3,0.8-0.6,1.3-0.7c0.5-0.2,0.9-0.3,1.3-0.3
		c0.3,0,0.7,0,1,0.1s0.6,0.2,0.9,0.4c0.3,0.2,0.5,0.4,0.6,0.7c0.2,0.3,0.2,0.6,0.2,0.9c0,0.2,0,0.3,0,0.4s-0.1,0.2-0.1,0.3
		c-0.1,0.1-0.1,0.1-0.2,0.2s-0.2,0.1-0.4,0.2c-0.1,0-0.2,0-0.4,0.1s-0.3,0.1-0.5,0.1c-0.2,0.1-0.4,0.1-0.6,0.2
		c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.1-0.5,0.1s-0.3,0.1-0.4,0.1c0,0-0.1,0-0.3,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.4,0.1
		c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.5,0.4
		c0.2,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.1,0.8,0.1s0.5,0,0.8,0s0.4,0,0.6,0c0.7,0,1.3,0,1.9-0.1s1.3-0.1,1.9-0.1v0.2
		c0,0.1,0,0.2,0,0.2s0,0.2-0.1,0.2s-0.1,0.1-0.2,0.1c-0.5,0.1-0.9,0.2-1.3,0.2c-0.4,0.1-0.7,0.1-1.1,0.1c-0.3,0-0.7,0-1.1,0
		c-0.4,0-0.8,0-1.2,0h-0.3c-0.1,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0H154c-0.4-0.1-0.8-0.3-1-0.5s-0.5-0.5-0.7-0.8
		c-0.2-0.3-0.3-0.7-0.4-1C151.7,190.5,151.7,190.2,151.7,189.8z M156.1,187.5c-0.2,0-0.4,0-0.6,0s-0.4,0.1-0.7,0.2
		c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.2,0.4-0.2,0.6h0.8c0.3,0,0.6,0,0.9,0
		c0.3,0,0.6-0.1,0.9-0.1c0.3-0.1,0.5-0.1,0.8-0.3s0.4-0.3,0.5-0.5s0.2-0.4,0.2-0.7v-0.1l-0.2-0.2c0,0-0.1,0-0.1,0
		c-0.1,0-0.1,0-0.2,0s-0.2,0-0.2,0C156.1,187.5,156.1,187.5,156.1,187.5z"
          />
          <path
            d="M167.6,191.5c-0.3,0-0.7,0.1-1,0.2s-0.7,0.3-1,0.6s-0.7,0.4-1.1,0.6c-0.4,0.2-0.8,0.2-1.2,0.2c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2,0c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.3-0.4-0.4-0.6
		c-0.1-0.2-0.1-0.5-0.2-0.7s-0.1-0.5-0.1-0.7v-0.5c0-0.2,0-0.5,0-0.8s0-0.6,0.1-0.8c0-0.3,0.1-0.5,0.1-0.8c0.1-0.2,0.2-0.4,0.3-0.6
		s0.3-0.3,0.5-0.3c0.1,0,0.2,0,0.2,0.1s0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.2s0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4
		c0,0.1-0.1,0.3-0.1,0.4s-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.4s0,0.2,0,0.3c0,0.2,0,0.4,0,0.6
		c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.4,0.2,0.6s0.2,0.3,0.4,0.4c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.1,0
		c0.7,0,1.4-0.2,2-0.5s1.2-0.7,1.6-1.3c0-0.1,0-0.2,0-0.4c0-0.2,0-0.5,0-0.7s0-0.5,0-0.7c0-0.2,0-0.4,0-0.5c0-0.1,0-0.2,0-0.2
		c0-0.1,0.1-0.2,0.1-0.2s0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.2-0.1c0.2,0,0.3,0.1,0.4,0.2s0.1,0.3,0.2,0.6c0,0.2,0.1,0.5,0.1,0.8
		s0.1,0.6,0.1,0.9c0.1,0.3,0.2,0.6,0.3,0.9s0.4,0.6,0.7,0.8c0.3,0.2,0.7,0.4,1.2,0.6c0.5,0.2,1.1,0.3,1.8,0.3c0,0,0,0.1,0,0.1
		s0,0.1,0,0.1c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.5,0c-0.4,0-0.7-0.1-1.1-0.3
		s-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.4-0.6-0.6C167.7,191.6,167.6,191.5,167.6,191.5z"
          />
        </g>
        <g>
          <g>
            <line
              className={classes.pencils}
              x1="25.8"
              y1="151.4"
              x2="257.6"
              y2="151.4"
            />
            <path
              className={classes.bar}
              d="M257.6,151.8H25.8c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h231.8c0.2,0,0.4,0.2,0.4,0.4
			S257.8,151.8,257.6,151.8z"
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.1,151.1c-0.5-4.1-0.5-8.4-0.3-12.5c0.1-2,0.3-3.9,0.6-5.9c0.3-1.8,2.4-2.2,3.9-2.2
					c1.5,0,3.4,0.4,4.3,1.7c1.1,1.6,1.2,4.3,1.4,6.1c0.4,4.4-0.1,8.7-0.2,13c0,0.5,0.7,0.5,0.7,0c0.1-5.2,0.7-10.2-0.1-15.4
					c-0.2-1.7-0.6-3.7-1.9-4.9c-1-0.9-2.6-1.2-3.9-1.3c-1.3-0.1-2.9,0.2-3.9,1.1c-1.2,1-1.2,2.5-1.3,4c-0.6,5.3-0.8,11-0.1,16.3
					C105.5,151.5,106.2,151.5,106.1,151.1L106.1,151.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M105.9,143.4c3.2-1.2,6.9-0.4,10.2,0.2c0.5,0.1,0.7-0.6,0.2-0.7c-3.4-0.6-7.2-1.4-10.6-0.2
					C105.2,142.8,105.4,143.5,105.9,143.4L105.9,143.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.1,131.6c-5.4-4.1-4.8-14.2-1.9-19.6c2.7-5.1,9.9-11.4,7.5-17.8c-0.2,0.1-0.5,0.2-0.7,0.3
					c4.4,6.6,9.5,13.2,10.3,21.3c0.5,5.6-0.4,13.7-6,16.5c-0.4,0.2-0.1,0.9,0.4,0.6c5.7-2.9,6.7-10.6,6.5-16.3
					c-0.4-8.7-5.8-15.6-10.4-22.5c-0.2-0.3-0.8-0.1-0.7,0.3c1.5,3.8-1.1,7.7-3.2,10.8c-1.9,2.9-4.3,5.6-5.3,9
					c-1.8,5.6-1.9,14.2,3.3,18.1C106.2,132.6,106.5,131.9,106.1,131.6L106.1,131.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.6,150.9c0.1-0.1,0.3-0.2,0.4-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.2-0.4,0.4
					C106.1,150.8,106.4,151.1,106.6,150.9L106.6,150.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.5,150.2c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.3-0.3,0.5
					C106.1,150.2,106.4,150.4,106.5,150.2L106.5,150.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.4,149.5c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.2-0.3,0.3-0.4,0.5
					C106,149.6,106.3,149.7,106.4,149.5L106.4,149.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.3,148.7c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.2-0.4,0.4-0.5,0.6
					C105.9,148.8,106.2,148.9,106.3,148.7L106.3,148.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.1,148c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.2-0.2,0.3-0.4,0.4
					C105.7,147.8,105.9,148.2,106.1,148L106.1,148z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.2,147.3c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.1-0.1,0.2-0.2,0.3
					C105.8,147.1,106,147.4,106.2,147.3L106.2,147.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.1,146.4c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.2,0.2-0.4,0.3
					C105.7,146.4,105.9,146.6,106.1,146.4L106.1,146.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.1,145.7c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.2-0.4,0.4
					C105.7,145.7,106,145.9,106.1,145.7L106.1,145.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.1,144.9c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.2-0.4,0.4
					C105.7,144.9,106,145.1,106.1,144.9L106.1,144.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106,144.2c0.1-0.1,0.2-0.2,0.2-0.2c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.2,0.2
					C105.6,144.1,105.9,144.4,106,144.2L106,144.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.1,142.2c0-0.1,0.1-0.2,0.1-0.2c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					C105.7,142.3,106.1,142.4,106.1,142.2L106.1,142.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.1,141.3c0.2,0,0.3-0.2,0.4-0.3c0.1-0.2-0.2-0.4-0.3-0.2c0,0.1-0.1,0.1-0.1,0.2
					C105.7,141,105.8,141.4,106.1,141.3L106.1,141.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.2,140.4c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C105.8,140.4,106.1,140.6,106.2,140.4L106.2,140.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.4,139.4c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.2-0.4,0.4
					C105.9,139.4,106.2,139.6,106.4,139.4L106.4,139.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.3,138.4c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2-0.2-0.4-0.3-0.2c0,0.1-0.1,0.2-0.2,0.2
					C105.9,138.2,106.1,138.5,106.3,138.4L106.3,138.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.4,137.4c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.4
					C106,137.4,106.3,137.6,106.4,137.4L106.4,137.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.4,136.7c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.3-0.4,0.5
					C106,136.7,106.3,136.9,106.4,136.7L106.4,136.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.5,135.7c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.1-0.2,0.2-0.3,0.3
					C106.1,135.5,106.3,135.9,106.5,135.7L106.5,135.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.7,134.8c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2-0.2-0.4-0.3-0.2c0,0.1-0.1,0.2-0.2,0.2
					C106.3,134.6,106.5,134.9,106.7,134.8L106.7,134.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.8,133.7c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.4,0.3-0.5,0.4
					C106.3,133.8,106.7,133.9,106.8,133.7L106.8,133.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M107,132.6c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.1,0.1-0.2,0.2-0.4,0.3
					C106.6,132.5,106.9,132.8,107,132.6L107,132.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M107.3,131.7c0.2,0,0.4-0.1,0.7-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.1-0.5,0.2
					C107,131.4,107,131.7,107.3,131.7L107.3,131.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M103.6,127.9c0.6,1.1,1.1,2.5,2.3,3.1c0.2,0.1,0.4-0.2,0.2-0.3c-1.1-0.5-1.6-1.9-2.1-2.9
					C103.8,127.5,103.4,127.7,103.6,127.9L103.6,127.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M104.6,128.3c0.4,0.8,0.9,1.6,1.5,2.2c0.2,0.2,0.4-0.1,0.3-0.3c-0.6-0.6-1.1-1.3-1.5-2.1
					C104.8,127.9,104.5,128.1,104.6,128.3L104.6,128.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M105.4,128.1c0.1,0.8,0.8,1.4,1.4,2c0.2,0.2,0.4-0.1,0.3-0.3c-0.5-0.5-1.2-1.1-1.3-1.8
					C105.7,127.8,105.4,127.9,105.4,128.1L105.4,128.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M106.6,128.3c0.3,0.4,0.6,0.8,0.9,1.2c0.2,0.2,0.4-0.1,0.3-0.3c-0.3-0.3-0.6-0.7-0.9-1.1
					C106.8,127.9,106.5,128.1,106.6,128.3L106.6,128.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M116,131.3c1.2-0.3,1.8-1.2,2.5-2.2c0.1-0.2-0.2-0.4-0.3-0.2c-0.6,0.9-1.1,1.8-2.3,2.1
					C115.6,131,115.7,131.3,116,131.3L116,131.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M115.5,130.9c0.8-0.4,1.5-0.8,2-1.6c0.1-0.2-0.2-0.4-0.3-0.2c-0.5,0.7-1.2,1.1-1.9,1.5
					C115.1,130.7,115.3,131,115.5,130.9L115.5,130.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M114.7,130.4c0.6-0.3,1.2-0.6,1.6-1.2c0.1-0.2-0.1-0.5-0.3-0.3c-0.4,0.5-1,0.8-1.6,1.1
					C114.3,130.2,114.4,130.5,114.7,130.4L114.7,130.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M114.2,129.9c0.4-0.3,0.7-0.6,0.8-1.1c0.1-0.2-0.3-0.3-0.4-0.1c-0.1,0.4-0.3,0.7-0.7,0.9
					C113.9,129.7,114,130,114.2,129.9L114.2,129.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M113.5,129.6c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.2-0.3,0.4-0.4,0.5
					C113.1,129.5,113.4,129.8,113.5,129.6L113.5,129.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M107.6,128.7c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4-0.1,0.3-0.3c-0.1-0.1-0.3-0.3-0.4-0.5
					C107.8,128.4,107.4,128.5,107.6,128.7L107.6,128.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M113.1,129.3c0.1-0.1,0.1-0.2,0.1-0.2c0.1-0.2-0.2-0.4-0.3-0.2c0,0.1-0.1,0.2-0.1,0.2
					C112.7,129.3,113,129.5,113.1,129.3L113.1,129.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M108.5,129.1C108.5,129.1,108.6,129.1,108.5,129.1c0.3,0,0.3-0.4,0-0.4c0,0,0,0,0,0
					C108.3,128.7,108.3,129.1,108.5,129.1L108.5,129.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M118.8,128.4C118.8,128.4,118.8,128.3,118.8,128.4c0-0.3-0.4-0.3-0.4,0c0,0,0,0,0,0
					C118.4,128.6,118.8,128.6,118.8,128.4L118.8,128.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M103.4,127.6C103.4,127.6,103.5,127.6,103.4,127.6c0.1,0,0.2-0.1,0.2-0.1c0,0,0,0,0,0
					c0-0.2-0.3-0.3-0.4-0.1c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0C103.1,127.2,103.2,127.6,103.4,127.6L103.4,127.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M103.3,126.8C103.3,126.8,103.3,126.8,103.3,126.8c0.2-0.2,0-0.5-0.2-0.3c0,0,0,0,0,0
					C102.8,126.7,103.1,127,103.3,126.8L103.3,126.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M119,127.9c0.2,0,0.2-0.4,0-0.4S118.7,127.9,119,127.9L119,127.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M102.8,126c0.2,0,0.2-0.4,0-0.4S102.6,126,102.8,126L102.8,126z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M102.7,125.2c0.2,0,0.2-0.4,0-0.4C102.5,124.8,102.5,125.2,102.7,125.2L102.7,125.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M119.4,127.2C119.4,127.2,119.4,127.2,119.4,127.2c0.3,0,0.3-0.4,0.1-0.4c0,0,0,0-0.1,0
					C119.1,126.8,119.1,127.2,119.4,127.2L119.4,127.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M120,126.2C120,126.2,120,126.2,120,126.2c0-0.3-0.4-0.3-0.4-0.1c0,0,0,0,0,0.1
					C119.6,126.5,120,126.5,120,126.2L120,126.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M117.6,128.9c0.2,0,0.2-0.4,0-0.4C117.3,128.5,117.3,128.9,117.6,128.9L117.6,128.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M118.1,128.1c0.2,0,0.2-0.4,0-0.4C117.9,127.7,117.9,128.1,118.1,128.1L118.1,128.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M104.3,127.8c0.2,0,0.2-0.4,0-0.4S104,127.8,104.3,127.8L104.3,127.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M103.9,126.7C103.9,126.6,103.9,126.6,103.9,126.7c0-0.3-0.4-0.3-0.4-0.1c0,0,0,0,0,0.1
					C103.5,126.9,103.9,126.9,103.9,126.7L103.9,126.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M116.6,128.5C116.6,128.5,116.7,128.5,116.6,128.5c0.3,0,0.3-0.4,0.1-0.4c0,0,0,0-0.1,0
					C116.4,128.2,116.4,128.5,116.6,128.5L116.6,128.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M104.8,127.2C104.8,127.2,104.8,127.2,104.8,127.2c0,0.3,0.4,0.3,0.4,0.1c0,0,0,0,0-0.1
					C105.2,126.9,104.8,126.9,104.8,127.2L104.8,127.2z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  className={classes.pencils}
                  d="M137.9,151.2c0,0,0-11.1,0.2-16.6c-0.1,0.1-0.2,0.2-0.4,0.3c4.9-0.2,9.7,0,14.6,0
					c-0.1-0.1-0.2-0.3-0.4-0.4c0.2,5.5,0.2,11,0,16.5c0,0.5,0.7,0.5,0.7,0c0.2-5.5,0.2-11,0-16.5c0-0.2-0.2-0.4-0.4-0.4
					c-4.9,0-9.7-0.2-14.6,0c-0.2,0-0.4,0.3-0.4,0.5c-0.2,5.5-0.2,10.6-0.2,16.6H137.9L137.9,151.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.1,135c0-1.9-0.1-4,1.3-5.5c1.4-1.5,4.2-1.4,6.1-1.3c1.7,0.1,3.9,0,5.3,1.3c1.3,1.2,1.2,3.5,1.2,5.2
					c0,0.5,0.7,0.5,0.7,0c0-1.7,0-4-1.2-5.4c-1.4-1.6-3.5-1.6-5.4-1.7c-2.2-0.2-5.1-0.2-6.9,1.2c-1.9,1.5-1.9,4.1-1.9,6.3
					C137.3,135.5,138.1,135.5,138.1,135L138.1,135z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M139.1,129.3c-1.2-2.4-2-5.1-2.9-7.6c-1-2.8-2.2-6.1-2.2-9.1c0-0.9,0.7-1.8,1.1-2.5
					c0.9-1.5,1.8-2.9,2.8-4.3c2.1-3.1,4.2-6.1,6.6-8.9c0.5-0.6,0.2-0.6,0.7,0.1c0.4,0.5,0.8,1,1.2,1.6c1.2,1.6,2.4,3.1,3.5,4.7
					c1.9,2.6,3.9,5.2,5.3,8.1c1,2.1-0.5,5.3-1.1,7.3c-0.9,3-1.9,5.9-3,8.8c-0.2,0.6-0.4,1.1-0.6,1.7c-0.2,0.5,0.6,0.6,0.7,0.2
					c0.9-2.6,1.9-5.1,2.7-7.7c0.9-2.9,2.3-6.2,2.3-9.3c0-0.9-0.6-1.8-1.1-2.6c-1-1.7-2.2-3.4-3.3-5c-2.2-3.1-4.5-6.2-6.9-9.1
					c-0.1-0.2-0.4-0.1-0.5,0c-2.5,2.8-4.7,5.9-6.8,9c-1.1,1.6-2.1,3.2-3.1,4.8c-0.5,0.8-1.1,1.7-1.3,2.7c-0.2,1.1,0.1,2.4,0.3,3.4
					c0.4,1.9,1,3.7,1.6,5.5c0.9,2.9,1.9,5.9,3.3,8.6C138.7,130.1,139.4,129.7,139.1,129.3L139.1,129.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M144.5,96.5c0,6,0,12.1,0,18.1c0,0.5,0.7,0.5,0.7,0c0-6,0-12.1,0-18.1C145.2,96,144.5,96,144.5,96.5
					L144.5,96.5z"
                />
              </g>
              <path
                className={classes.pencils}
                d="M147.1,116.2c-0.1-1-0.9-2.1-1.9-2c-1.2,0-2,0.9-2.1,2.1c-0.1,1.3,1,2.1,2.2,2.1
				C146.4,118.3,147.2,117.4,147.1,116.2z"
              />
              <g>
                <path
                  className={classes.pencils}
                  d="M138.3,151.3c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
					C137.9,151,138.1,151.4,138.3,151.3L138.3,151.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.2,150.7c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
					C137.8,150.6,138,150.9,138.2,150.7L138.2,150.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.3,150.1c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.2,0.2-0.4,0.2
					C137.9,149.9,138.1,150.2,138.3,150.1L138.3,150.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.3,149.4c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.1-0.2,0.2
					C137.9,149.2,138.1,149.5,138.3,149.4L138.3,149.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.3,148.7c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.2-0.6,0.4
					C137.8,148.6,138.1,148.8,138.3,148.7L138.3,148.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.5,147.9c0.1-0.1,0.2-0.1,0.2-0.1c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.3,0.1-0.4,0.2
					C138,147.8,138.3,148.1,138.5,147.9L138.5,147.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.3,147.2c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.2,0.1-0.3,0.2
					C137.9,147,138.1,147.3,138.3,147.2L138.3,147.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.4,146.4c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.1-0.2,0.2-0.3,0.3
					C138,146.3,138.2,146.6,138.4,146.4L138.4,146.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.3,145.5c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
					C137.9,145.3,138.1,145.6,138.3,145.5L138.3,145.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.3,144.9c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C137.9,144.7,138.1,145,138.3,144.9L138.3,144.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.4,144c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.5,0.3
					C138,143.9,138.3,144.2,138.4,144L138.4,144z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.3,143c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.5,0.3
					C138,142.8,138.1,143.1,138.3,143L138.3,143z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.5,142.3c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.2-0.5,0.4
					C138,142.1,138.3,142.4,138.5,142.3L138.5,142.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.5,141.4c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C138.1,141.3,138.4,141.5,138.5,141.4L138.5,141.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.5,140.7c0.1-0.2,0.2-0.2,0.4-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.3,0.1-0.5,0.2-0.6,0.4
					C138,140.7,138.4,140.9,138.5,140.7L138.5,140.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.5,139.9c0.1-0.1,0.1-0.2,0.3-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.5,0.4
					C138.1,139.9,138.4,140.1,138.5,139.9L138.5,139.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.5,139.2c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.2-0.5,0.4
					C138.1,139.1,138.3,139.4,138.5,139.2L138.5,139.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.5,138.5c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.1-0.3,0.2
					C138.1,138.4,138.4,138.7,138.5,138.5L138.5,138.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.4,137.6c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.3,0.1-0.4,0.3
					C138,137.7,138.3,137.8,138.4,137.6L138.4,137.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.4,136.9c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.1-0.1,0.2-0.3,0.2
					C138,136.7,138.2,137,138.4,136.9L138.4,136.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.6,136c0.1-0.1,0.1-0.1,0.2-0.1c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.3,0.1-0.4,0.2
					C138.2,136,138.4,136.2,138.6,136L138.6,136z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.5,134.1c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.6,0.3
					C138.1,134,138.3,134.2,138.5,134.1L138.5,134.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.5,133.3c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C138.1,133.2,138.3,133.5,138.5,133.3L138.5,133.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.6,132.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.1-0.6,0.3
					C138.2,132.3,138.4,132.5,138.6,132.3L138.6,132.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.9,131.2c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.2-0.6,0.4
					C138.5,131.1,138.7,131.4,138.9,131.2L138.9,131.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M139.2,130.4c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.1-0.3,0.2
					C138.9,130.1,139,130.5,139.2,130.4L139.2,130.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M140,129.5c0.1,0,0.2,0,0.4,0c0.2,0,0.2-0.4,0-0.4c-0.2,0-0.3,0-0.5,0.1
					C139.6,129.2,139.7,129.5,140,129.5L140,129.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.3,135.4c0.2,0,0.3-0.1,0.4-0.2c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.1,0.1-0.2,0.1
					C138,135.1,138.1,135.5,138.3,135.4L138.3,135.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M139.2,128.3c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C138.7,128.2,139,128.5,139.2,128.3L139.2,128.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M139,127.7c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.3-0.5,0.5
					C138.5,127.8,138.9,127.9,139,127.7L139,127.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.6,127.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.2-0.4,0.4
					C138.2,127.1,138.5,127.3,138.6,127.1L138.6,127.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.4,126.5c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.2,0.3
					C138,126.4,138.2,126.7,138.4,126.5L138.4,126.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M138.1,125.8c0-0.1,0.1-0.2,0.2-0.3c0.1-0.2-0.1-0.5-0.3-0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					C137.7,125.9,138,126.1,138.1,125.8L138.1,125.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M137.9,125c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.3,0.4
					C137.5,125.2,137.9,125.2,137.9,125L137.9,125z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M137.6,124.3c0-0.1,0.1-0.2,0.2-0.3c0.1-0.2-0.1-0.5-0.3-0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					C137.2,124.3,137.5,124.5,137.6,124.3L137.6,124.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M137.4,123.6c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.2-0.2,0.3-0.3,0.4
					C137,123.5,137.2,123.8,137.4,123.6L137.4,123.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M137.3,122.8c0,0,0.1-0.1,0.1-0.1c0.1-0.2-0.2-0.4-0.3-0.2c0,0-0.1,0.1-0.1,0.1
					C136.9,122.8,137.2,123,137.3,122.8L137.3,122.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M136.9,122.2c0-0.1,0.1-0.1,0.1-0.2c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C136.5,122.2,136.8,122.4,136.9,122.2L136.9,122.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M136.6,121.4c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.2-0.3,0.3-0.3,0.6
					C136.2,121.5,136.5,121.6,136.6,121.4L136.6,121.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M136.2,120.5c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.1-0.2,0.2-0.3,0.3
					C135.8,120.4,136,120.7,136.2,120.5L136.2,120.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M136.1,119.4c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.3-0.3-0.4-0.1c0,0,0,0.1,0,0.1
					C135.6,119.3,135.9,119.6,136.1,119.4L136.1,119.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M135.7,118.4c0-0.1,0.1-0.1,0.1-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.3,0.4
					C135.3,118.5,135.6,118.6,135.7,118.4L135.7,118.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M135.4,117.7c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.2-0.3,0.4
					C135,117.7,135.3,117.9,135.4,117.7L135.4,117.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M135,116.8c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.1-0.1,0.2-0.2,0.3
					C134.6,116.7,134.9,117,135,116.8L135,116.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M134.9,115.8c0-0.1,0.1-0.1,0.1-0.2c0.2-0.1-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.2,0.3
					C134.5,115.9,134.8,116,134.9,115.8L134.9,115.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M134.6,114.8c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2-0.2-0.4-0.3-0.2c0,0.1-0.1,0.1-0.1,0.2
					C134.2,114.6,134.4,114.9,134.6,114.8L134.6,114.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M134.6,113.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.3-0.3,0.4
					C134.1,113.8,134.5,114,134.6,113.8L134.6,113.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M134.6,112.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C134.1,112.4,134.4,112.7,134.6,112.5L134.6,112.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M134.8,111.5C134.8,111.5,134.8,111.4,134.8,111.5c0.3,0,0.3-0.4,0-0.4c-0.1,0-0.1,0-0.2,0.1
					C134.4,111.3,134.6,111.6,134.8,111.5L134.8,111.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M135.4,110.5C135.4,110.5,135.5,110.5,135.4,110.5c0.3,0,0.3-0.4,0-0.4c0,0,0,0,0,0
					C135.2,110.1,135.2,110.5,135.4,110.5L135.4,110.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M136.1,109.4C136.1,109.4,136.1,109.4,136.1,109.4c0.2-0.1,0-0.4-0.2-0.3c-0.1,0-0.1,0.1-0.1,0.2
					C135.7,109.5,136.1,109.6,136.1,109.4L136.1,109.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M136.7,108.4C136.7,108.4,136.7,108.4,136.7,108.4c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0,0.1,0.1
					C136.6,108.9,136.9,108.6,136.7,108.4C136.7,108.4,136.7,108.4,136.7,108.4c-0.2-0.2-0.4,0.1-0.3,0.3c0,0,0,0,0,0
					C136.6,108.8,136.8,108.6,136.7,108.4L136.7,108.4z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.2,151.5c-0.2-5.7,0-11.4,0-17.1c0-2.2-0.4-5.3,2.1-6.2c2-0.8,4.5-0.6,6.6-0.5
					c2.2,0,4.5-0.2,6.6,0.7c1.9,0.8,1.3,5.1,1.3,6.8c-0.2,5.4,0.2,10.8-0.3,16.1c0,0.5,0.7,0.5,0.7,0c0.3-3.5,0.3-7,0.3-10.5
					c0-3,0.1-6,0-9c-0.1-1.6-0.1-3.3-1.7-4.2c-2-1.1-4.7-0.8-6.9-0.8c-2.2,0-4.6-0.1-6.8,0.6c-1.8,0.5-2.4,2.1-2.6,3.8
					c-0.4,3,0,6.2-0.1,9.2c-0.1,3.7-0.2,7.3,0,11C172.5,152,173.2,152,173.2,151.5L173.2,151.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.2,128.5c0-2.4-0.1-4.9-0.1-7.3c0-1.9-0.4-4.2,1.2-5.6c2.4-2.2,6.9-2.3,9.9-1.7
					c1.4,0.3,3,0.9,3.6,2.3c0.4,1,0.1,2.6,0.1,3.7c0,2.8,0,5.6,0,8.5c0,0.5,0.7,0.5,0.7,0c0-3.4,0-6.8,0-10.3c0-3.1-1.9-4.5-4.9-5
					c-3.2-0.6-7.4-0.2-10,2c-1.4,1.1-1.3,2.6-1.3,4.2c-0.1,3.1,0,6.2,0,9.2C173.4,128.9,174.2,128.9,174.2,128.5L174.2,128.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M172.9,134.5c5.7,0,11.5,0,17.2,0c0.5,0,0.5-0.7,0-0.7c-5.7,0-11.5,0-17.2,0
					C172.4,133.8,172.4,134.5,172.9,134.5L172.9,134.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,151.6c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.2-0.5,0.3
					C173.1,151.3,173.3,151.7,173.5,151.6L173.5,151.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,150.9c0.1-0.1,0.1-0.2,0.3-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.4,0.3
					C173,150.9,173.4,151.1,173.5,150.9L173.5,150.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.4,150.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C172.9,150,173.2,150.3,173.4,150.1L173.4,150.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,149.3c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.1-0.5-0.1-0.4c-0.2,0.1-0.3,0.2-0.5,0.3
					C173.1,149.2,173.3,149.5,173.5,149.3L173.5,149.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.4,148.6c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.1-0.1,0.2-0.3,0.2
					C173.1,148.4,173.2,148.7,173.4,148.6L173.4,148.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,147.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.2-0.4,0.4
					C173,147.8,173.4,148,173.5,147.8L173.5,147.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,146.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.4,0.4
					C173,146.8,173.3,147,173.5,146.8L173.5,146.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.4,145.9c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C173,145.7,173.2,146,173.4,145.9L173.4,145.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,145.1c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.6,0.3
					C173.1,145,173.4,145.2,173.5,145.1L173.5,145.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,144.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2-0.1-0.5-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C173,144.1,173.3,144.3,173.5,144.2L173.5,144.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.4,143.2c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.4,0.3
					C173.1,143,173.2,143.3,173.4,143.2L173.4,143.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.4,142.3c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C173.1,142.1,173.2,142.4,173.4,142.3L173.4,142.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,141.4c0.2-0.1,0.4-0.1,0.6-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.5,0.2
					C173.2,141.1,173.3,141.4,173.5,141.4L173.5,141.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.7,140.6c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C173.2,140.5,173.5,140.8,173.7,140.6L173.7,140.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,139.8c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.3-0.5,0.4
					C173,139.7,173.3,140,173.5,139.8L173.5,139.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,138.9c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
					C173.1,138.7,173.3,139,173.5,138.9L173.5,138.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,138.2c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.2,0.2-0.4,0.2
					C173.2,137.9,173.3,138.2,173.5,138.2L173.5,138.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.6,137.4c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2-0.1-0.5-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C173.2,137.2,173.4,137.6,173.6,137.4L173.6,137.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,136.7c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.4,0.3-0.6,0.5
					C173.1,136.6,173.4,136.9,173.5,136.7L173.5,136.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.6,135.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C173.1,135.7,173.4,136,173.6,135.8L173.6,135.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.7,135c0-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.2-0.3,0.4
					C173.3,135.1,173.6,135.2,173.7,135L173.7,135z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.7,133.8c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C173.3,133.6,173.5,134,173.7,133.8L173.7,133.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.7,133.1c0.2-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.2-0.6,0.4
					C173.2,133,173.5,133.2,173.7,133.1L173.7,133.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.4,132.3c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.1-0.3,0.2
					C173.1,132,173.2,132.4,173.4,132.3L173.4,132.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.5,131.5c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.1,0.1-0.2,0.1-0.3,0.2
					C173.1,131.3,173.3,131.6,173.5,131.5L173.5,131.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M173.7,130.9c0.2-0.1,0.3-0.1,0.4-0.3c0.1-0.2-0.1-0.5-0.3-0.3c-0.1,0.1-0.1,0.2-0.2,0.2
					C173.4,130.6,173.5,130.9,173.7,130.9L173.7,130.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.3,129.8c0.1-0.1,0.2-0.1,0.3-0.1c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.3,0.1-0.5,0.2
					C173.8,129.7,174.1,130,174.3,129.8L174.3,129.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.7,129c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0-0.3,0.1-0.5,0.1
					C174.4,128.6,174.4,129,174.7,129L174.7,129z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M175.6,128.4c0.2,0,0.3,0,0.5,0c0.2,0,0.2-0.4,0-0.4c-0.2,0-0.3,0-0.5,0
					C175.4,128.1,175.4,128.4,175.6,128.4L175.6,128.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.6,127.5c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C174.2,127.3,174.4,127.6,174.6,127.5L174.6,127.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.6,126.8c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.4,0.3
					C174.2,126.6,174.4,126.9,174.6,126.8L174.6,126.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.6,126c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.4,0.3
					C174.2,125.9,174.4,126.2,174.6,126L174.6,126z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.7,125.3c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.5,0.4
					C174.2,125.3,174.5,125.4,174.7,125.3L174.7,125.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.7,124.5c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.3-0.5,0.4
					C174.2,124.4,174.5,124.7,174.7,124.5L174.7,124.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.7,123.9c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.1,0.1-0.5-0.1-0.4c-0.2,0.1-0.3,0.2-0.5,0.3
					C174.3,123.7,174.5,124,174.7,123.9L174.7,123.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.6,123.1c0.2,0,0.3-0.1,0.5-0.2c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.1-0.3,0.1
					C174.2,122.8,174.3,123.2,174.6,123.1L174.6,123.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.5,122.4c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.6,0.3
					C174.1,122.3,174.3,122.6,174.5,122.4L174.5,122.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.6,121.6c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C174.2,121.5,174.4,121.7,174.6,121.6L174.6,121.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.6,120.8c0.1-0.1,0.1-0.2,0.3-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.4,0.3
					C174.2,120.8,174.5,121,174.6,120.8L174.6,120.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.6,120.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C174.1,119.9,174.4,120.2,174.6,120.1L174.6,120.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.5,119.2c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C174.1,119,174.3,119.4,174.5,119.2L174.5,119.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.6,118.5c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.2,0.2-0.4,0.2
					C174.3,118.2,174.3,118.6,174.6,118.5L174.6,118.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.8,117.7c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.2-0.4,0-0.4c-0.2,0-0.4,0.1-0.6,0.2
					C174.4,117.6,174.7,117.9,174.8,117.7L174.8,117.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M174.9,117c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.1-0.3,0.2
					C174.6,116.7,174.7,117.1,174.9,117L174.9,117z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M175.5,116c0.2,0,0.3-0.1,0.5-0.1c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.3,0.1-0.5,0.1
					C175.2,115.7,175.3,116.1,175.5,116L175.5,116z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M176.3,115.3c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.1-0.4-0.1-0.4c-0.1,0-0.3,0.1-0.4,0.1
					C175.9,115,176,115.3,176.3,115.3L176.3,115.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M177.4,114.7c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0-0.4-0.2-0.3c0,0-0.1,0-0.1,0
					C177.1,114.4,177.1,114.7,177.4,114.7L177.4,114.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M179,114.2C179,114.2,179,114.1,179,114.2c0-0.3-0.4-0.3-0.4,0c0,0,0,0,0,0
					C178.6,114.4,179,114.4,179,114.2L179,114.2z"
                />
              </g>
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.5,151.1c0,0,0.1-18.6,0.1-28c-0.1,0.1-0.2,0.3-0.4,0.4c5.2-0.2,10.4-0.5,15.6-0.5
				c-0.1-0.1-0.2-0.2-0.4-0.4c0.2,9.5,0.5,19,0.7,28.4c0,0.5,0.8,0.5,0.7,0c-0.2-9.5-0.5-19-0.7-28.4c0-0.2-0.2-0.4-0.4-0.4
				c-5.2,0-10.4,0.3-15.6,0.5c-0.2,0-0.4,0.1-0.4,0.3c0,9.4-0.1,19-0.1,28C210.7,151.9,211.5,151.9,211.5,151.1
				C211.5,151.1,211.5,151.9,211.5,151.1z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.6,123.3c0.6-3.3,1.6-6.6,2.5-9.8c0.5-1.8,1-3.5,1.6-5.2c0.2-0.6,0.3-1.4,0.9-1.4
				c1.6,0.1,3.2,0,4.7-0.1c0.1,0,1.7-0.1,1.7-0.2c0.1,0.2,0.1,0.5,0.2,0.8c0.2,0.8,0.4,1.5,0.5,2.3c0.4,1.9,0.8,3.8,1.2,5.7
				c0.5,2.5,1,5,1.5,7.5c0.1,0.5,0.8,0.3,0.7-0.2c-1.1-5.5-2-11.1-3.5-16.5c0-0.2-0.2-0.3-0.4-0.3c-2.5,0.1-4.9,0.2-7.4,0.1
				c-0.1,0-0.3,0.1-0.3,0.2c-1.2,2.5-1.8,5.2-2.5,7.9c-0.8,2.9-1.7,5.9-2.2,8.9C210.8,123.6,211.5,123.8,211.6,123.3L211.6,123.3z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M216.3,106.6c0.4-1.4,0.8-2.8,1.2-4.2c0.2-0.8,0.4-1.5,0.6-2.3c0.2-0.6-0.1-0.1-0.2-0.1
				c0.4,0,0.8,0,1.2,0c0.8,0,1.6-0.1,2.4-0.1c-0.4,0-0.4-0.5-0.2,0.2c0.1,0.4,0.2,0.8,0.3,1.2c0.4,1.5,0.7,3.1,1.1,4.6
				c0.1,0.5,0.8,0.3,0.7-0.2c-0.5-2.1-1-4.2-1.5-6.4c0-0.2-0.2-0.3-0.4-0.3c-1.2,0-2.5,0.1-3.7,0.1c-0.2,0-0.3,0.1-0.4,0.3
				c-0.6,2.3-1.2,4.6-1.9,6.9C215.5,106.8,216.2,107,216.3,106.6L216.3,106.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M218.6,123.4c0,0,0,18.7,0,27.7h0.7c0-9,0-18.7,0-27.7C219.3,122.7,218.6,122.7,218.6,123.4
				C218.6,123.4,218.6,122.7,218.6,123.4z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M219.3,93.5c0,0,0,3.7,0,6c0,0.7,0.7,0.7,0.7,0c0-2.2,0-3.7,0-6C220.1,92.8,219.3,92.8,219.3,93.5
				C219.3,93.5,219.3,92.8,219.3,93.5z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.8,151.4c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
				C211.5,151.1,211.6,151.5,211.8,151.4L211.8,151.4z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,150.7c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.2-0.6,0.4
				C211.5,150.6,211.8,150.8,211.9,150.7L211.9,150.7z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,150c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.4,0.3
				C211.5,149.8,211.7,150.1,211.9,150L211.9,150z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,149.2c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.5,0.3
				C211.4,149.1,211.7,149.4,211.9,149.2L211.9,149.2z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M212,148.3c0.1-0.1,0.1-0.2,0.3-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.5,0.4
				C211.6,148.3,211.9,148.5,212,148.3L212,148.3z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,147.6c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.1-0.4-0.1-0.4c-0.1,0-0.3,0.1-0.4,0.2
				C211.5,147.4,211.7,147.7,211.9,147.6L211.9,147.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.8,147c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0-0.4,0.1-0.5,0.2
				C211.5,146.8,211.7,147.1,211.8,147L211.8,147z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,146.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.1-0.6,0.3
				C211.5,146.3,211.7,146.5,211.9,146.3L211.9,146.3z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,145.6c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
				C211.4,145.5,211.7,145.7,211.9,145.6L211.9,145.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,144.8c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.1-0.4-0.1-0.4c-0.3,0.1-0.5,0.2-0.7,0.4
				C211.5,144.7,211.7,144.9,211.9,144.8L211.9,144.8z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.8,143.9c0.3-0.1,0.5-0.2,0.7-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
				C211.4,143.6,211.5,144,211.8,143.9L211.8,143.9z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,142.9c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.4
				C211.5,142.8,211.8,143.1,211.9,142.9L211.9,142.9z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,141.9c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.1-0.5-0.1-0.4c-0.1,0.1-0.3,0.1-0.4,0.2
				C211.5,141.7,211.7,142,211.9,141.9L211.9,141.9z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,141.2c0.1-0.1,0.3-0.3,0.4-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.4
				C211.5,141.1,211.8,141.4,211.9,141.2L211.9,141.2z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M212.1,140.3c0-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.2-0.5,0.5
				C211.7,140.5,212.1,140.6,212.1,140.3L212.1,140.3z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.8,139.5c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.5,0.3
				C211.4,139.4,211.7,139.7,211.8,139.5L211.8,139.5z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.8,138.5c0.1-0.2,0.3-0.3,0.4-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.2-0.5,0.4
				C211.4,138.5,211.7,138.7,211.8,138.5L211.8,138.5z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,137.5c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.4,0.3-0.6,0.4
				C211.5,137.3,211.7,137.7,211.9,137.5L211.9,137.5z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.8,136.5c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
				C211.5,136.2,211.6,136.6,211.8,136.5L211.8,136.5z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.8,135.6c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.2-0.3,0.2-0.5,0.3
				C211.4,135.4,211.6,135.7,211.8,135.6L211.8,135.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,134.6c0.2-0.1,0.4-0.3,0.7-0.4c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.2-0.7,0.4
				C211.6,134.5,211.7,134.8,211.9,134.6L211.9,134.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.8,133.6c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.3,0.1-0.5,0.2
				C211.4,133.4,211.6,133.7,211.8,133.6L211.8,133.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,132.5c0.2-0.1,0.3-0.1,0.5-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.2
				C211.6,132.2,211.7,132.6,211.9,132.5L211.9,132.5z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M212,131.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
				C211.5,131.7,211.8,131.9,212,131.8L212,131.8z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,131c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.2-0.3,0.3-0.5,0.4
				C211.5,130.8,211.7,131.1,211.9,131L211.9,131z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,129.9c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.4
				C211.4,129.8,211.7,130.1,211.9,129.9L211.9,129.9z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M212,128.9c0.1-0.1,0.3-0.2,0.4-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.2-0.4,0.4
				C211.5,128.8,211.8,129,212,128.9L212,128.9z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M212,127.9c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.3-0.5,0.4
				C211.6,127.9,211.9,128.1,212,127.9L212,127.9z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,127.1c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
				C211.6,126.9,211.7,127.3,211.9,127.1L211.9,127.1z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,126.1c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
				C211.6,125.9,211.7,126.3,211.9,126.1L211.9,126.1z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M212,125.5c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.3-0.4,0.5
				C211.5,125.5,211.8,125.6,212,125.5L212,125.5z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.8,124.6c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.1-0.3,0.3-0.5,0.5
				C211.4,124.6,211.7,124.8,211.8,124.6L211.8,124.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M211.9,123.9c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
				C211.4,123.7,211.7,124,211.9,123.9L211.9,123.9z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M212.1,122.5c0.2-0.1,0.4-0.1,0.6-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.1-0.4,0.2
				C211.8,122.1,211.9,122.5,212.1,122.5L212.1,122.5z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M212.3,121.6c0.2-0.1,0.4-0.1,0.5-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.1-0.3,0.2
				C212,121.3,212.1,121.6,212.3,121.6L212.3,121.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M212.6,120.5c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.1-0.5,0.2
				C212.3,120.2,212.4,120.5,212.6,120.5L212.6,120.5z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M213,119.4c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.3,0.1-0.5,0.2
				C212.6,119.2,212.8,119.6,213,119.4L213,119.4z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M213.2,118.6c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.1-0.6,0.3
				C212.8,118.4,212.9,118.7,213.2,118.6L213.2,118.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M213.4,117.6c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
				C213,117.4,213.2,117.7,213.4,117.6L213.4,117.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M213.8,116.6c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.2,0.2-0.4,0.2
				C213.5,116.3,213.6,116.7,213.8,116.6L213.8,116.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M214,115.8c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
				C213.6,115.6,213.8,116,214,115.8L214,115.8z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M214.1,115c0.2-0.1,0.3-0.1,0.5-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.2
				C213.8,114.7,213.9,115,214.1,115L214.1,115z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M214.5,114c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.5,0.3
				C214.1,113.9,214.4,114.2,214.5,114L214.5,114z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M214.7,113.3c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
				C214.4,113.1,214.5,113.4,214.7,113.3L214.7,113.3z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M215.1,112.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0-0.4-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
				C214.7,112,214.9,112.2,215.1,112.1L215.1,112.1z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M215.3,111.2c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.4,0.3-0.6,0.4
				C214.9,111,215.1,111.3,215.3,111.2L215.3,111.2z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M215.6,110.3c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.2,0.2-0.4,0.2
				C215.3,110.1,215.4,110.4,215.6,110.3L215.6,110.3z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M215.8,109.6c0.2-0.1,0.3-0.1,0.4-0.2c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.1-0.2,0.1
				C215.4,109.4,215.5,109.7,215.8,109.6L215.8,109.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M216.1,108.8c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.1-0.3,0.2
				C215.7,108.6,215.9,108.9,216.1,108.8L216.1,108.8z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M216.4,108.1c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.3,0.2-0.5,0.3
				C216,107.9,216.2,108.2,216.4,108.1L216.4,108.1z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M216.7,107.5c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
				C216.3,107.3,216.5,107.6,216.7,107.5L216.7,107.5z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M216.9,106.1c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.1-0.3,0.1
				C216.6,105.8,216.7,106.2,216.9,106.1L216.9,106.1z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M217.1,105.4c0.2,0,0.3-0.1,0.4-0.2c0.2-0.2-0.1-0.5-0.3-0.3c0,0-0.1,0.1-0.1,0.1
				C216.8,105,216.8,105.4,217.1,105.4L217.1,105.4z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M217.5,104.4c0.1,0,0.2-0.1,0.2-0.1c0.2-0.1,0-0.5-0.2-0.3c0,0-0.1,0.1-0.1,0.1
				C217.2,104,217.3,104.4,217.5,104.4L217.5,104.4z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M217.8,103.4C217.9,103.4,217.9,103.4,217.8,103.4c0.3-0.1,0.2-0.5,0-0.4c-0.1,0-0.2,0.1-0.3,0.2
				C217.4,103.4,217.7,103.6,217.8,103.4L217.8,103.4z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M218,102.6c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0-0.4-0.2-0.3c-0.1,0-0.2,0.1-0.3,0.1
				C217.6,102.3,217.8,102.7,218,102.6L218,102.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M218.4,101.6C218.4,101.6,218.4,101.6,218.4,101.6c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0-0.2,0.1-0.3,0.3
				C218,101.7,218.3,101.8,218.4,101.6L218.4,101.6z"
              />
            </g>
            <g>
              <path
                className={classes.pencils}
                d="M218.4,100.7c0.2,0,0.2-0.4,0-0.4C218.2,100.3,218.2,100.7,218.4,100.7L218.4,100.7z"
              />
            </g>
            <g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.8,150.7c-0.1-5.3-0.2-10.7-0.2-16c0-2.2-0.2-4.6,0.2-6.8c0.5-2.4,1.3-4.7,2.1-7.1
					c2.1-6.1,4.4-12.1,7.3-17.9c0.6-1.3,1.4-3.6,2.7-4.4c0.5-0.3,2.5,3.4,2.6,3.7c2.5,4.6,4.5,9.5,6.5,14.4c1.6,3.9,3.4,8,4.3,12.2
					c0.2,1.2,0.3,2.6,0.3,3.8c0,2.5,0,5.1,0,7.6c0,3.7,0,7.3,0,11c0,0.7,0.7,0.7,0.7,0c0-4.5,0-9.4,0-14.2c0-4,0.1-7.7-1.1-11.5
					c-1.8-5.8-4.2-11.4-6.6-16.9c-1.1-2.6-2.4-5.1-3.7-7.5c-0.6-1-1.4-2.8-2.6-3.3c-0.9-0.3-1.6,1-1.9,1.5c-1.2,1.9-2.2,4.1-3.1,6.2
					c-2.5,5.6-4.7,11.3-6.6,17.1c-0.6,1.9-1.3,3.9-1.5,5.9c-0.3,2.3-0.1,4.7-0.1,7c0,5.1,0.1,10.1,0.2,15.2
					C56,151.2,56.8,151.2,56.8,150.7L56.8,150.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.5,129c3.4-4.4,6.7,5.4,6.6,8c0,0.5,0.7,0.5,0.7,0c0.2-3.7,0.8-8.7,5.1-9.5c5.7-1.2,6.5,6.2,6.7,10.1
					c0,0.5,0.7,0.5,0.7,0c0.3-3.6,0.4-10,5.7-8.4c0.5,0.1,0.7-0.5,0.2-0.6c-5.9-1.7-6.3,4.8-6.6,9.2c0.2,0,0.5,0,0.7,0
					c-0.3-4.5-1.1-11.4-7.1-11c-5.1,0.3-6,6.3-6.2,10.2c0.2,0,0.5,0,0.7,0c0.1-3.3-3.9-13.8-8-8.4C55.6,129,56.2,129.4,56.5,129
					L56.5,129z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57.6,126.5c0.2-0.2,0.4-0.5,0.6-0.7c0.1-0.2-0.1-0.5-0.3-0.3c-0.2,0.2-0.4,0.5-0.6,0.7
					C57.2,126.4,57.5,126.7,57.6,126.5L57.6,126.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M58,125.1c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.2-0.4,0.4-0.6,0.7
					C57.6,125.1,57.9,125.3,58,125.1L58,125.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M58.3,123.8c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.2-0.4,0.4-0.6,0.6
					C57.9,123.7,58.2,124,58.3,123.8L58.3,123.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M58.9,122.6c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.1,0-0.5-0.2-0.3c-0.3,0.2-0.5,0.3-0.8,0.5
					C58.4,122.5,58.7,122.7,58.9,122.6L58.9,122.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M59.2,121.3c0.2-0.2,0.5-0.4,0.7-0.5c0.2-0.1,0-0.4-0.2-0.3c-0.3,0.1-0.5,0.3-0.7,0.5
					C58.8,121.1,59,121.5,59.2,121.3L59.2,121.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M59.6,120.1c0.1-0.3,0.3-0.4,0.6-0.6c0.2-0.1,0-0.4-0.2-0.3c-0.3,0.1-0.6,0.4-0.8,0.7
					C59.2,120.1,59.5,120.3,59.6,120.1L59.6,120.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M60.1,118.8c0.2-0.2,0.5-0.4,0.8-0.5c0.2-0.1,0-0.4-0.2-0.3c-0.3,0.2-0.6,0.3-0.8,0.6
					C59.7,118.7,60,119,60.1,118.8L60.1,118.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M60.4,117.8c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.1,0-0.4-0.2-0.3c-0.3,0.1-0.5,0.3-0.7,0.5
					C60,117.8,60.3,118,60.4,117.8L60.4,117.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M60.7,116.9c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.2-0.5,0.3-0.7,0.5
					C60.3,116.7,60.5,117,60.7,116.9L60.7,116.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M61.2,115.8c0.2-0.2,0.3-0.4,0.6-0.5c0.2-0.1,0.1-0.4-0.1-0.4c-0.3,0.1-0.6,0.3-0.8,0.5
					C60.8,115.7,61,116,61.2,115.8L61.2,115.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M61.5,114.6c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.2-0.5,0.3-0.7,0.5
					C61.1,114.5,61.3,114.8,61.5,114.6L61.5,114.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M62.1,113.4c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.1,0-0.4-0.2-0.3c-0.3,0.1-0.5,0.3-0.7,0.5
					C61.6,113.3,61.9,113.6,62.1,113.4L62.1,113.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M62.5,112.4c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.1-0.4-0.1-0.4c-0.3,0.1-0.5,0.3-0.7,0.6
					C62.1,112.4,62.4,112.6,62.5,112.4L62.5,112.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M62.7,111.4c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.2-0.4,0.3-0.6,0.4
					C62.3,111.2,62.5,111.5,62.7,111.4L62.7,111.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M63,110.5c0.2,0,0.3-0.1,0.4-0.1c0.2-0.1,0-0.4-0.2-0.3c-0.1,0.1-0.2,0.1-0.3,0.1
					C62.6,110.2,62.7,110.6,63,110.5L63,110.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57.5,127.5c0.2-0.1,0.3-0.2,0.4-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C57.1,127.3,57.3,127.6,57.5,127.5L57.5,127.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57.3,150.7c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.2-0.4,0.3-0.6,0.4
					C56.9,150.5,57.1,150.8,57.3,150.7L57.3,150.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57.2,150c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
					C56.8,149.8,57,150.1,57.2,150L57.2,150z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57,149.3c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
					C56.6,149.1,56.8,149.4,57,149.3L57,149.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57.1,148.5c0.1-0.2,0.3-0.3,0.4-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.4,0.2-0.5,0.4
					C56.7,148.4,57,148.7,57.1,148.5L57.1,148.5z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57,147.7c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.2-0.1-0.5-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.2
					C56.7,147.4,56.8,147.7,57,147.7L57,147.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57,146.8c0.2-0.1,0.3-0.2,0.4-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C56.6,146.6,56.8,146.9,57,146.8L57,146.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,145.7c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.2-0.4,0.3-0.6,0.5
					C56.6,145.5,56.8,145.8,56.9,145.7L56.9,145.7z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57.1,144.6c0.2-0.1,0.3-0.3,0.5-0.5c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.3,0.3-0.4,0.4
					C56.7,144.4,56.9,144.7,57.1,144.6L57.1,144.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57.2,143.6c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.2-0.3,0.3-0.4,0.5
					C56.7,143.6,57,143.8,57.2,143.6L57.2,143.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57.1,142.6c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.3-0.4,0.4
					C56.6,142.6,56.9,142.8,57.1,142.6L57.1,142.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57.1,141.8c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2-0.1-0.4-0.3-0.3c-0.2,0.2-0.4,0.4-0.6,0.6
					C56.7,141.7,57,142,57.1,141.8L57.1,141.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,140.9c0.2-0.2,0.4-0.3,0.6-0.6c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.2-0.3,0.3-0.4,0.4
					C56.5,140.7,56.7,141,56.9,140.9L56.9,140.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,139.9c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.4,0.3
					C56.5,139.7,56.7,140,56.9,139.9L56.9,139.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57,138.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C56.6,138.7,56.9,139,57,138.8L57,138.8z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57,137.9c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C56.6,137.8,56.9,138.1,57,137.9L57,137.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57,137c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.4
					C56.5,136.9,56.8,137.2,57,137L57,137z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M57,136.1c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2-0.2-0.4-0.3-0.2c-0.1,0.2-0.2,0.3-0.4,0.4
					C56.6,135.9,56.8,136.3,57,136.1L57,136.1z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,135.3c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C56.5,135,56.6,135.4,56.9,135.3L56.9,135.3z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,134.2c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.1-0.4-0.1-0.4c-0.2,0.1-0.4,0.2-0.6,0.4
					C56.5,134.1,56.7,134.4,56.9,134.2L56.9,134.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,133.4c0.1-0.1,0.3-0.2,0.4-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.3,0.3
					C56.5,133.2,56.7,133.6,56.9,133.4L56.9,133.4z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,132.6c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0-0.4-0.2-0.3c-0.2,0.1-0.3,0.2-0.5,0.3
					C56.5,132.4,56.7,132.7,56.9,132.6L56.9,132.6z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,131.9c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0-0.5-0.2-0.3c-0.2,0.1-0.3,0.3-0.5,0.4
					C56.6,131.7,56.7,132,56.9,131.9L56.9,131.9z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,131.2c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.2-0.1-0.4-0.3-0.3c-0.1,0.1-0.2,0.2-0.4,0.3
					C56.5,131,56.7,131.3,56.9,131.2L56.9,131.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,130.2c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.2,0.2-0.4,0.3
					C56.5,130,56.7,130.3,56.9,130.2L56.9,130.2z"
                />
              </g>
              <g>
                <path
                  className={classes.pencils}
                  d="M56.9,129.4c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0-0.5-0.2-0.3c-0.1,0.1-0.3,0.2-0.4,0.3
					C56.6,129.3,56.8,129.6,56.9,129.4L56.9,129.4z"
                />
              </g>
              <path
                className={classes.pencils}
                d="M75.2,109.2C75.2,109.2,75.2,109.2,75.2,109.2c-0.8-1.9-4.8-11.1-6.3-11.1c-1.5,0-5.2,9-6,11
				c-0.1,0-0.2,0-0.3,0.1c-0.5,0.1-0.3,0.8,0.2,0.7c3.9-1,8.2-0.6,12.1,0C75.5,110,75.7,109.3,75.2,109.2z"
              />
            </g>
          </g>
        </g>
        <circle fill="#85C48E" cx="55.7" cy="87.9" r="9.2" />
        <circle fill="#EE701D" cx="166.8" cy="93.9" r="9.2" />
        <circle fill="#F6C7BF" cx="232.7" cy="89.4" r="5.4" />
        <circle fill="#F9CE46" cx="150.1" cy="83.8" r="5.2" />
        <circle fill="#85C48E" cx="123" cy="93" r="3.7" />
        <circle fill="#F9CE46" cx="197.8" cy="129.5" r="3.7" />
        <circle fill="#C389BC" cx="90.6" cy="109.5" r="6.8" />
      </svg>
    </div>
  )
}

export default FooterLogo
